import React, { useEffect, useState } from 'react'
import LaunchIcon from '@material-ui/icons/Launch';
import {
  Box, 
  Chip,
  Typography,
  Tooltip,
  Zoom,
  Button,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Popup from "../../components/Popup";
import TicketDetail from "./TicketDetail";
import {initWidget} from '../../helper/Widget';
import { authorizeFirebaseUser } from "../../services/authService";
import Cookies from 'universal-cookie';
import { getEventPerformanceViewData } from '../../services/eventService'
import Notification from '../../components/Notification';

const Stats = ({ eventTitle, status, revenue, customerdateformat, soldTickets,currencytype,id,eventdetailid,eventid,accountid,disable,event_subcategory,reservedTickets, box_office_amber_state, ...props }) => {
  const history = useHistory();
  const [openPopup, setOpenPopup] = React.useState(false);
  const [eventValue, setEventValue] = React.useState("");
  const [eventDetailValue, setEventDetailValue] = React.useState("");
  const cookies = new Cookies();
  const currentUser = cookies.get('currentUser');
  const [checkinUrl, setCheckinUrl] = useState('');
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const [eventOccurrence, setEventOccurrence] = useState({});

  const modals=()=>{
    setOpenPopup(true); 
    setEventValue(eventid)
    setEventDetailValue(eventdetailid)
  }

  const notification = (message, type) => {
    setNotify({
      isOpen: true,
      message: message,
      type: type
    })
  }

  const errorHandle = (error) => {
    const messages = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
    notification(messages, 'error');
  }

  const getCheckInListUrl = async (e) => {
    try {
      const firebaseHost = process.env.REACT_APP_FIREBASE_CHECKIN_APP;
      const data = {
        uid: e.uid,
        eventId: e.id,
        eventName: e.title,
        organizationId: e.OrganizationId,
        enable_qr: true,
        enterpriseMongoEventId: eventid,
      };

      const res = await authorizeFirebaseUser(data)
      const token = res.customToken;
      const orgId = res.orgId;
      const parameters = `token=${token}&eventId=${e.id}&orgId=${orgId}&enable_qr=true&is_paddington=${data.eventName === "The Paddington Bear Experience" ? true : false}&enterpriseMongoEventId=${eventid}`;
      return `${firebaseHost}?${parameters}`;
    } catch (error) {
      errorHandle(error);
    }
  }

  const onBoxofficeClick = async () => {
    if (checkinUrl === '') {
      const eventPerformanceViewData = await getEventPerformanceViewData(eventdetailid);
      const eventOccurrence = eventPerformanceViewData.data.eventOccurrence;
      setEventOccurrence(eventOccurrence || {});
      const eventData = {
        uid: currentUser.userid.toString(),
        email: currentUser.email,
        OrganizationId: eventOccurrence?.eventdetailfile?.accountid,
        id: eventdetailid,
        title: eventOccurrence.eventname
      };
      console.log('eventOccurrence',eventOccurrence);

      const checkinURL = await getCheckInListUrl(eventData);
      setCheckinUrl(checkinURL);
    } else {
      window.open(checkinUrl, '_blank');
    }
  };

  useEffect(() => {
    // Open the link in a new tab once 'link' state is set
    if (checkinUrl) {
      window.open(checkinUrl, '_blank');
    }
  }, [checkinUrl]); // Re-run this effect whenever 'link' changes

  const openWidget = ()=>{

    initWidget(accountid,eventid,eventdetailid);
        props.setPopup && props.setPopup(false)
  }

  return (
   <div>
         <Box className="item flex">
            <Box variant="h5" sx={{flexGrow: 1}} className="event-title"> {eventTitle}{event_subcategory ? `: ${event_subcategory}` : ''}
            {disable ? <Tooltip onClick={openWidget} TransitionComponent={Zoom} title="Walk-In Sales" arrow><LaunchIcon className="launch-icon" /></Tooltip> :
            <Tooltip  TransitionComponent={Zoom} title="Event Ended" arrow><LaunchIcon className="launch-icon disabled" /></Tooltip> }
            </Box> 
            {status ?
              <Box className="chip"><Chip label='Active' variant="outlined" size="small" className="success-outline"></Chip></Box> : 
              box_office_amber_state ? 
              <Box className="chip"><Chip label='Box-Office Only' variant="outlined" size="small" className="amber-outline"></Chip></Box> :
              <Box className="chip"><Chip label='Off-Sale' variant="outlined" size="small" className="bg-danger"></Chip></Box> }          
          </Box> 
        
        <Typography variant="h6" mb={3}>
          <Box className="item flex">
            {/* <Box fontWeight="fontWeightRegular" className="price"><span>{currencytype==='USD'?'$':'£'}</span>{revenue}</Box> 
            <Box className="date fSize-12 ml-2" sx={{flexGrow: 1}} >Revenue</Box>  */}
            <Box fontSize="small" sx={{m: 1}} className="date mr-0">{customerdateformat}</Box>
          </Box>
        </Typography>
        <Typography variant="subtitle2" mb={4} color="textSecondary">
          <Box className="item flex">
            <Box className="sold-ticket">{soldTickets} </Box>
            <Box className="sold fSize-12 ml-2" sx={{flexGrow: 1}} >Sold Tickets</Box> 
          </Box>
          {
            reservedTickets ? 
            <Box className="item flex">
              <Box className="sold-ticket">{reservedTickets} </Box>
              <Box className="sold fSize-12 ml-2" sx={{flexGrow: 1}} >Reserved Tickets </Box> 
            </Box> : null
          }
     
          <Box className="item flex">
            <Box className="p-1"><Button size="small" className="btn-redoutline py-0" onClick={modals}> Buyer&nbsp;List </Button></Box> 
            {/* <Box className="p-1"><Button size="small" className="btn-redoutline py-0" onClick={()=>{history.push('/QrValidation')}}> Scanner </Button></Box>  */}
            <Box className="p-1">
              <Button size="small" className="btn-redoutline py-0" 
                onClick={() => onBoxofficeClick()}>Scanner</Button>
            </Box>
            <Box className="p-1">
              <Button size="small" className="btn-redoutline py-0" 
                onClick={() => history.push(`/PerformanceView/${eventdetailid}`)}>Performance View</Button>
            </Box>
          </Box>
        </Typography>
        <Popup title={`${eventTitle}-Buyer List`} openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <TicketDetail eventID={eventValue} eventDetailID={eventDetailValue} eventTitle={eventTitle} dateTime={customerdateformat}/>
      </Popup>

      <Notification
      notify={notify}
      setNotify={setNotify}
    />
        </div>  
  );
};

export default Stats;
