import {
  Grid, DialogActions, Divider, Select, MenuItem, FormControl, InputLabel, OutlinedInput, Checkbox, ListItemText, Container, Button, Switch, Typography,
  TextField
} from '@material-ui/core';
import React, { useState } from "react";
import moment from 'moment';

import Controls from '../../components/controls/Controls';
import Form from '../../components/Form';
import useForm from '../../components/useForm';
import Notification from '../../components/Notification';
import Popup from '../../components/Popup';
import { updateEventDetailsBulk } from '../../services/eventService';
import EventsFilter from "../../components/EventsFilter";
import Cookies from 'universal-cookie';
import locale from 'date-fns/locale/en-GB'
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib


if (locale && locale.options) {
  locale.options.weekStartsOn = 1
 }

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const getFirstAndLastDay = () => {
  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return {
    firstDay: moment(firstDay).format('YYYY-MM-DD'),
    lastDay: moment(lastDay).format('YYYY-MM-DD')
  };
}

const EditorWrapperSafety = ({ callback, safetyLabel = 'Specify Label', children, ...props }) => {
  const [safety, setSafety] = useState(true);
  return safety ? <Button size="large" fullWidth className="mr-3 btn-redoutline" onClick={() => { setSafety(false); callback && callback(); }}>{safetyLabel}</Button> : children
}

const BulkEditEventModal = ({ openPopup, setOpenPopup, accountId, eventId, occurrences = [], mapinventory }) => {
  const { firstDay, lastDay } = getFirstAndLastDay();
  const initialFValues = {
    eventId: eventId,
    startDate: firstDay,
    endDate: lastDay,
    selectedOcurrences: [],
    allPeformancesCheckbox: false,
    matching_rule: null,
    box_office_amber_state: false,
  };
  const cookies = new Cookies();
  const currentUser = cookies.get('currentUser');
  const customDateFormat = currentUser.customdateformat !== undefined ? currentUser.customdateformat.toUpperCase() : 'MM-DD-YYYY';
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const [filteredOccurrences, setFilteredOccurrences] = useState(occurrences.filter(o => {
    return moment(o.EventDate).isSameOrAfter(moment(initialFValues.startDate)) &&
      moment(o.EventDate).isSameOrBefore(moment(initialFValues.endDate))
  }));
  const notification = (message, type) => {
    setNotify({
      isOpen: true,
      message: message,
      type: type
    })
  }
  const validateTicket = (numberoftickets) => {
    for (const e of filteredOccurrences) {
        if (values.selectedOcurrences.includes(e.Id) ) {
          const soldTicketCOunt = (e.numberoftickets - e.ticketcountstatus);
          if ( soldTicketCOunt > Number(numberoftickets)) {
            return `${e.EventDateTime},${soldTicketCOunt}`;
          }
        }
    }
   return null;
  };
  
  
  const validate = (fieldValues = values) => {
    const temp = { ...errors };
    if ("numberoftickets" in fieldValues && (values.selectedOcurrences).length > 0) {
      const value = validateTicket(fieldValues.numberoftickets);
      if (value) {
        const [date, ticketcount] = value?.split(",");
        temp.numberoftickets = `Please check again. The number of tickets you are providing is lower than the sold count of ${ticketcount}. Make sure to verify the event date, which is ${date}.`;
      } else {
        temp.numberoftickets = "";
      }
    }
    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const { values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    setFieldValue
  } = useForm(initialFValues, true, validate);

  const handleOnEventFilterChange = matchingRule => {
    // e is formatted data as event_id, days_of_week, day_times
    // setMatchingRule(e);
    const initialOccurrences = occurrences.filter(o => {
      return moment(o.EventDate).isSameOrAfter(moment(values.startDate)) &&
        moment(o.EventDate).isSameOrBefore(moment(values.endDate));
    });
    const filteredData = matchingRule ? initialOccurrences.filter(o => filterTicketTypeForOccurrence(o, matchingRule)) : [];
    setFilteredOccurrences(filteredData.length > 0 ? filteredData : initialOccurrences);
    setFieldValue('selectedOcurrences', filteredData.map(t => t.Id));
  };

  const updateFilterOnBlur = () => {
    setFilteredOccurrences(occurrences.filter(o => {
      return moment(o.EventDate).isSameOrAfter(moment(values.startDate)) &&
        moment(o.EventDate).isSameOrBefore(moment(values.endDate))
    }));
  };

  const filterTicketTypeForOccurrence = (eventDetail, matching_rule) => {
    if (!matching_rule) {
      return true;
    }
    const { days_of_week, day_times } = matching_rule;
    const eventDateTime = new Date(eventDetail.eventdatetimeISO);
    const eventDay = days[eventDateTime.getDay()];
    let eventTime = eventDetail.EventTime;

    // If nothing is selected, return true
    let hasHoursSelected = day_times.length ? day_times.includes(eventTime) : true;
    let hasDaysSelected = days_of_week.length ? days_of_week.includes(eventDay) : true;
    
    if(hasHoursSelected && hasDaysSelected) {
      return true;
    } 
    return false;
  }

  const handleSubmited = () => {
    if (validate()) {
    let data = {...values};

    if(!(values.onsaleflag && values.box_office_amber_state)) {
      if(data.onsaledate){
        const formatString = "MM-DD-YYYY,h:mmA";
        data.onsaledate = moment((data.onsaledate), formatString).format(formatString); 
        }
        updateEventDetailsBulk(data).then((result) => {
          notification("Event Bulk Updated Successfully. Please refresh to see all changes.", "success");
        }).catch((error) => {
          const messages = error.response?.data?.message || (error.response && !error.response.data.message ? error.response.data : error.message);
          notification(messages, "error");
        })
      } else {
        notification("'On Sale' and 'Box Office Only Sale' cannot be enabled simultaneously.", "error");
      }
    }
  }

  const onKeyPressHandler = e=> {
    if ((e.charCode >= 48 && e.charCode <= 57 && e.charCode !== 101) || e.charCode===46) {
    }
    else{
      e.preventDefault(); 
    }
 }
  return (
    <Popup title="Bulk Edit"
      openPopup={openPopup}
      setOpenPopup={setOpenPopup}>
      <Form>

        <Notification
          notify={notify}
          setNotify={setNotify}
        />

        <Grid container spacing={2} md={12} >


          <Grid item lg={6} md={6} sm={6} xs={12}>

            <Controls.Input
              name="startDate"
              label="Start Date"
              placeholder="YYYY-MM-DD"
              value={values.startDate || ''}
              onChange={handleInputChange}
              onBlur={updateFilterOnBlur}
              error={errors.startDate} className="smaller-input" />

          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={12}>

            <Controls.Input
              name="endDate"
              placeholder="YYYY-MM-DD"
              label="End Date"
              value={values.endDate || ''}
              onChange={handleInputChange}
              onBlur={updateFilterOnBlur}
              error={errors.endDate} className="smaller-input" />

          </Grid>
          <Grid item xs={12}>
            <EventsFilter occurrences={occurrences} accountid={accountId} event_id={eventId} days={values.matching_rule ? values.matching_rule.days_of_week : []} dayTimes={values.matching_rule ? values.matching_rule.day_times : []} onChange={(e) => handleOnEventFilterChange(e)} useAdditionalTimeslots={true} />
          </Grid>
          <Grid item xs={12} >

            <Grid container alignItems="center">
              <Grid item xs={12}>
              <FormControl
                variant="outlined"
                fullWidth
              >
                <InputLabel>Performances</InputLabel>
                <Select
                  label="Ocurrences"
                  fullWidth
                  value={values.selectedOcurrences}
                  name="selectedOcurrences"
                  multiple
                  input={<OutlinedInput label="Occurrences" />}
                  onChange={handleInputChange}
                  renderValue={(selected) => {
                    return `${selected.length} Events`
                  }}
                >{

                  filteredOccurrences.map(
                      (value, index) => (
                        <MenuItem key={value.Id} value={value.Id}>
                          <Checkbox checked={values.selectedOcurrences?.indexOf(value.Id) > -1} />
                          <ListItemText primary={`${value.EventDate} ${value.EventTime} (${moment(value.EventDate, 'YYYY-MM-DD').format('dddd')})`} />
                        </MenuItem>
                      )
                    )

                  }

                </Select>
              </FormControl>

              </Grid>
              {/* <Grid item xs={3}>
              <Controls.Checkbox disabled={occurrences.length === 0} name="allPeformancesCheckbox" label="Select All" checked={values.allPeformancesCheckbox} onChange={handleInputChange} />
              </Grid> */}
              

            </Grid>


          </Grid>

          {/* <Grid item lg={6} md={6} sm={6} xs={12}>
            <EditorWrapperSafety safetyLabel="Change On Sale Date">
              <Controls.Input
                name="onsaledate"
                label="On Sale Date"
                placeholder="YYYY-MM-DD"
                value={values.onsaledate || ''}
                onChange={handleInputChange}
                error={errors.onsaledate} className="smaller-input" />
            </EditorWrapperSafety>
          </Grid> */}


          <Grid item lg={6} md={6} sm={6} xs={12}>
            <EditorWrapperSafety safetyLabel="Change Peformance Message"callback={() => { setValues({
              ...values,
              custommessage: '',
            })}}>
              <Controls.Input
                name="custommessage"
                label="Performance Message"
                value={values.custommessage || ''}
                onChange={handleInputChange}
                error={errors.custommessage} className="smaller-input" />
            </EditorWrapperSafety>


          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={12}>
            <EditorWrapperSafety safetyLabel="Change Ticket Price">
              <Controls.Input
                name="ticketcost"
                label="Ticket Price"
                type="number"
                value={values.ticketcost || ''}
                onChange={handleInputChange}
                inputProps={{ min: 0 }}
                error={errors.ticketcost} className="smaller-input"
              />
            </EditorWrapperSafety>
          </Grid>



          <Grid item lg={6} md={6} sm={6} xs={12}>

            <EditorWrapperSafety safetyLabel="Enable OnSale/OffSale" callback={() => { setValues({
              ...values,
              onsaleflag: false,
              box_office_amber_state: false,
            })}}>
              <Controls.Checkbox name="onsaleflag" label="On Sale" checked={values.onsaleflag} onChange={handleInputChange} />
              <Controls.Checkbox name="box_office_amber_state" label="Box Office Only Sale (Amber State)" checked={values.box_office_amber_state} onChange={handleInputChange} />
            </EditorWrapperSafety>
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={12}>
            <EditorWrapperSafety safetyLabel="Change On-Sale Date">
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                <DateTimePicker
                  inputVariant="outlined"
                  style={{ width: '100%' }}
                  label="Change On-Sale Date"
                  value={values.onsaledate || null}
                  // onChange={handleDateChange}
                  clearable
                  onChange={(newScheduleDateTime) => {
                    if (newScheduleDateTime) {
                      setValues({
                        ...values,
                        onsaledate: newScheduleDateTime,
                      });
                    }
                    else {
                      setValues({
                        ...values,
                        onsaledate: null,
                      });
                    }
                  }}
                  format="dd-MMM-yyyy, hh:mm a"
                  renderInput={(params) => <TextField {...params} className="date w-100" />}


                />
              </MuiPickersUtilsProvider>
            </EditorWrapperSafety>
          </Grid>

          <Grid item lg={6} md={6} sm={6} xs={12}>
            <EditorWrapperSafety safetyLabel="Delete Performance" callback={() => {
              setValues({
                ...values,
                deletePerformance: false,
              })
            }}>
              <Controls.Checkbox name="deletePerformance" label="Delete Performance" checked={values.deletePerformance} onChange={handleInputChange} />
            </EditorWrapperSafety>
          </Grid>

          { !mapinventory ? <Grid item lg={6} md={6} sm={6} xs={12}>
          <EditorWrapperSafety safetyLabel="Change Ticket Count">
          <Controls.Input
                name="numberoftickets"
                label="Change Ticket Count"
                type="number"
                value={values.numberoftickets || ''}
                onKeyPress={onKeyPressHandler}
                inputProps={{
                  maxLength: 5,
                  min: 0
                }}
                onChange={handleInputChange}
                error={errors.numberoftickets} className="smaller-input" />
                 </EditorWrapperSafety>
           </Grid>
           : null}
          
          <Divider />


          <DialogActions className="w-100">
            <Grid item lg={12} xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Controls.Button
                text="Submit" size="medium" onClick={handleSubmited} className="link-red" />
            </Grid>
          </DialogActions>
        </Grid>
      </Form>
    </Popup>
  )
}

export default BulkEditEventModal;
